import { render, staticRenderFns } from "./TaxonomyModal.vue?vue&type=template&id=066cef1c&scoped=true&"
import script from "./TaxonomyModal.vue?vue&type=script&lang=js&"
export * from "./TaxonomyModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066cef1c",
  null
  
)

export default component.exports